.search-bar-wrapper {
  display: flex; }
  .search-bar-wrapper .bp3-control-group {
    flex-grow: 1;
    flex-shrink: 1; }
  .search-bar-wrapper .bp3-select, .search-bar-wrapper .history-button {
    margin-left: 1em; }
  .search-bar-wrapper .bp3-button.bp3-intent-primary {
    margin-left: 1em; }
  .search-bar-wrapper .bp3-select:after {
    display: none; }
  .search-bar-wrapper .bp3-select .bp3-icon {
    color: #5c7080;
    position: absolute;
    right: 10px;
    top: 12px;
    pointer-events: none; }

@media only screen and (max-width: 720px) {
  .search-bar-wrapper {
    display: block;
    text-align: center; }
    .search-bar-wrapper .bp3-button.bp3-intent-primary {
      width: 100%;
      margin-left: 0em;
      margin-top: 1em; }
    .search-bar-wrapper .bp3-select, .search-bar-wrapper .history-button {
      margin-top: 1em;
      vertical-align: bottom; }
    .search-bar-wrapper .bp3-select {
      margin-left: 0em;
      margin-right: 1em; } }
